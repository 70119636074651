<template>
  <div>
    <search-documents @submit="submitSearch" />

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <div slot="name" slot-scope="name, record">
        <a @click="handleToDoc(record)" v-if="record.is_available">{{ name }}</a>
        <span v-else>{{ name }}</span>
      </div>

      <div slot="file_size" slot-scope="file_size">
        <span>{{ file_size | bToMbGb }}</span>
      </div>

      <div slot="is_free" slot-scope="is_free">
        <span v-if="is_free">免费</span>
        <span v-else>收费</span>
      </div>

      <div slot="is_recommend" slot-scope="is_recommend, record">
        <a-switch
          :checked="is_recommend"
          @change="changeIsRecommend(record)"
        />
      </div>

      <div slot="visible" slot-scope="visible, record">
        <a-switch
          :checked="visible"
          @change="changeIsShow(record)"
        />
      </div>

      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="handleEditDocument(record.id)">维护</a>
          <a-divider type="vertical" />
          <a @click="download(record)">下载</a>
          <a-divider type="vertical" />
          <a @click="deleteDocument(record.id)" class="delete">删除</a>
        </a-space>
      </span>
    </a-table>

    <Pagination
      :current="current"
      :total="total"
      @handleChange="handleChange"
      v-show="total > 20"
    />
  </div>
</template>

<script>
import SearchDocuments from '@/pages/document/list/Search'
import Pagination from "@/components/Pagination"
import { DocumentModel } from "@/models/document.js"
const documentModel = new DocumentModel()
import formatTime from '@/utils/formatTime'
import { bToMbGb } from '@/utils/filter.js'
import { ossDownloadFile } from "@/utils/oss";

export default {
  name: 'DocumentsList',
  components: {
    SearchDocuments,
    Pagination
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      editingId: 0,
      total: 0,
      current: 1
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: 'ID',
          dataIndex: 'id'
        },
        {
          title: '名称',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '格式',
          dataIndex: 'file_type'
        },
        {
          title: '大小',
          dataIndex: 'file_size',
          customRender: bToMbGb
        },
        {
          title: '文档授权',
          dataIndex: 'is_free',
          scopedSlots: { customRender: 'is_free' }
        },
        {
          title: '价格(积分)',
          dataIndex: 'point'
        },
        {
          title: '下载量',
          dataIndex: 'download_num'
        },
        {
          title: '点赞量',
          dataIndex: 'like_num'
        },
        {
          title: '一级分类',
          dataIndex: 'first_name'
        },
        {
          title: '二级分类',
          dataIndex: 'second_name'
        },
        {
          title: '三级分类',
          dataIndex: 'third_name'
        },
        {
          title: '上传者',
          dataIndex: 'creator'
        },
        {
          title: '上传日期',
          dataIndex: 'created_at',
          customRender: formatTime
        },
        {
          title: '是否为推荐项',
          dataIndex: 'is_recommend',
          scopedSlots: { customRender: 'is_recommend' }
        },
        {
          title: '是否显示',
          dataIndex: 'visible',
          scopedSlots: { customRender: 'visible' }
        },
        {
          title: '制作状态',
          dataIndex: 'make_status'
        },
        {
          title: '操作',
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        },
      ]
    }
  },
  methods: {
    submitSearch(search) {
      this.current = 1
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    handleChange(page) {
      this.current = page
      this.fetchData()
    },

    handleEditDocument(id) {
      this.$router.push({
        path: `/documents/${id}`
      })
    },

    deleteDocument(id) {
      const vm = this
      this.$confirm({
        title: '确定删除当前文档吗？删除后将不可恢复。',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          documentModel.destroy(id).then((res) => {
            if (res.code === 0) {
              vm.$notification.open({
                message: '提示',
                description: res.message,
                duration: 2
              })
              vm.fetchData()
            }
          })
        }
      })
    },

    download(record) {
      ossDownloadFile("softmall-documents", record.file_key, record.file_name)
    },

    changeIsRecommend(record) {
      documentModel.updateIsRecommend({ is_recommend: !record.is_recommend }, record.id)
        .then((res) => {
          if (res.code == 0) {
            this.$notification.open({
              message: "提示",
              description: res.message,
              duration: 2
            })
            record.is_recommend = !record.is_recommend
          }
        })
    },

    handleToDoc(record) {
      window.open(process.env.VUE_APP_PORT +
          "/doc/documents/" +
          record.hash_id)
    },

    changeIsShow(record) {
      documentModel.updateVisible({ visible: !record.visible }, record.id)
        .then((res) => {
          if (res.code == 0) {
            this.$notification.open({
              message: "提示",
              description: res.message,
              duration: 2
            })
            record.visible = !record.visible
          }
        })
    },
    
    fetchData() {
      this.loading = true
      const data = {
        ...this.query,
        page: this.current
      }
      documentModel.index(data).then(res => {
        if (res.code === 0) {
          this.data = res.result
          this.total = res.total
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.delete {
  color: red;
}
</style>
