<template>
  <div>
    <a-form
      class="sfm-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="12">
        <a-col :span="3">
          <a-form-item>
            <a-input
              v-decorator="['name_or_label']"
              placeholder="请输入文档名称或标签"
            >
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="3">
          <a-form-item>
            <a-select
              v-decorator="['first_category_id']"
              placeholder="请选择一级分类"
              showSearch
              allow-clear
              :filter-option="filterOption"
              :loading="loadingFirstCateOptions"
              @focus="fetchFirstCateOptions"
              @change="handleFirstCateChange"
            >
              <a-select-option
                v-for="item in firstCateOptions"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="3">
          <a-form-item>
            <a-select
              v-decorator="['second_category_id']"
              placeholder="请选择二级分类"
              allow-clear
              showSearch
              :filter-option="filterOption"
              :loading="loadingSecondCateOptions"
              @change="handleSecondCateChange"
            >
              <a-select-option
                v-for="item in secondCateOptions"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="3">
          <a-form-item>
            <a-select
              v-decorator="['third_category_id']"
              placeholder="请选择三级分类"
              showSearch
              allow-clear
              :filter-option="filterOption"
              :loading="loadingThirdCateOptions"
            >
              <a-select-option
                v-for="item in thirdCateOptions"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="3">
          <a-form-item>
            <a-select
              v-decorator="['is_free']"
              placeholder="文档授权"
            >
              <a-select-option value="true"> 免费 </a-select-option>
              <a-select-option value="false"> 收费 </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="3">
          <a-form-item>
            <a-select
              v-decorator="['is_recommend']"
              placeholder="是否为推荐项目"
            >
              <a-select-option value="true"> 推荐项目 </a-select-option>
              <a-select-option value="false"> 非推荐项目 </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="3">
          <a-form-item>
            <a-select
              v-decorator="['is_show']"
              placeholder="是否显示"
            >
              <a-select-option value="true"> 显示 </a-select-option>
              <a-select-option value="false"> 隐藏 </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item>
            <a-button type="primary" html-type="submit" class="search-button">
              搜索
            </a-button>
            <a-button @click="handleReset" class="search-button">
              刷新
            </a-button>
            <a-button type="primary" @click="showNewModal" class="search-button">
              新增文档
            </a-button>
            <a-button type="primary" @click="showBatchNewModal" class="search-button">
              批量上传文档
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <!-- 新增文档 -->
    <new-document
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="handleReset"
    />

    <!-- 批量新增文档 -->
    <batch-new-document
      v-if="isShowBatchNewModal"
      :visible.sync="isShowBatchNewModal"
      @completed="handleReset"
    />
  </div>
</template>

<script>
import { DocumentCategoryModel } from "@/models/document_category.js"
const documentCategoryModel = new DocumentCategoryModel()

export default {
  name: 'DocumentSearch',
  components: {
    NewDocument: () => import('@/pages/document/NewDocument'),
    BatchNewDocument: () => import('@/pages/document/BatchNewDocument')
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'document_search' }),
      firstCateOptions: [],
      secondCateOptions: [],
      thirdCateOptions: [],
      loadingFirstCateOptions: false,
      loadingSecondCateOptions: false,
      loadingThirdCateOptions: false,
      searchingRootId: 0,
      isShowNewModal: false,
      isShowBatchNewModal: false
    }
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showBatchNewModal() {
      this.isShowBatchNewModal = true
    },

    fetchFirstCateOptions() {
      if (this.firstCateOptions.length === 0) {
        this.loadingFirstCateOptions = true
        const data = {
          root_id: 0,
          parent_id: 0
        }
        documentCategoryModel.options(data).then(res => {
          if (res.code === 0) {
            res.result.forEach(item => {
              this.firstCateOptions.push({
                'id': item.id,
                'name': item.name
              })
            })
          }
          this.loadingFirstCateOptions = false
        })
      }
    },

    handleFirstCateChange(firstCateId) {
      this.secondCateOptions = []
      this.thirdCateOptions = []
      this.searchingRootId = 0

      this.form.setFieldsValue({
        second_cate_id: undefined,
        third_cate_id: undefined
      })

      if (firstCateId) {
        this.searchingRootId = firstCateId
        this.fetchSecondCateOptions({ root_id: firstCateId, parent_id: firstCateId })
      }
    },

    handleSecondCateChange(secondCateId) {
      this.thirdCateOptions = []

      this.form.setFieldsValue({
        third_cate_id: undefined
      })

      if (secondCateId) {
        this.fetchThirdCateOptions({ root_id: this.searchingRootId, parent_id: secondCateId })
      }
    },

    fetchSecondCateOptions(params) {
      if (this.secondCateOptions.length === 0) {
        this.loadingSecondCateOptions = true
        const data = {
          root_id: params.root_id,
          parent_id: params.parent_id
        }
        documentCategoryModel.options(data).then(res => {
          if (res.code === 0) {
            res.result.forEach(item => {
              this.secondCateOptions.push({
                'id': item.id,
                'name': item.name
              })
            })
          }
          this.loadingSecondCateOptions = false
        })
      }
    },

    fetchThirdCateOptions(params) {
      if (this.thirdCateOptions.length === 0) {
        this.loadingThirdCateOptions = true
        const data = {
          root_id: params.root_id,
          parent_id: params.parent_id
        }
        documentCategoryModel.options(data).then(res => {
          if (res.code === 0) {
            res.result.forEach(item => {
              this.thirdCateOptions.push({
                'id': item.id,
                'name': item.name
              })
            })
          }
          this.loadingThirdCateOptions = false
        })
      }
    },

    showModal() {},

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>

<style lang="scss" scoped>
.search-button {
  margin-right: 10px;
}
</style>
